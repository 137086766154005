import * as Api from '@/api/user/user'

import NoContent from '@/components/NoContent/NoContent'


import { translateTag, formatMoment } from '@/utils/utils'
import { activityType } from '@/enums/API'

export default {
  data () {
    return {
      isBtnBig: false,
      viewType: 'class',
      classData: [],
      privateData: [],
      liveData: [],
      videoData: [],
      noContentData: {
        pic: 'course_pic',
        tip: '您还没有此分类课程哦~'
      }
    }
  },
  mounted () {
    this.getClassData()
    this.getPrivateData()
    this.getCommunityLiveData()
    this.getCommunityVideoData()
  },
  methods: {
    getClassData () {
      Api.userCourseClass({is_expired: 1}, res => {
        this.classData = res
      }, '', 'v1')
    },
    getPrivateData () {
      Api.userCourse({ is_expired: 1}, res => {
        this.privateData = res
      }, '', 'v1')
    },
    getCommunityLiveData () {
      Api.publicCourse(activityType.publicClass,{is_expired: 1}, res => {
        this.liveData = res
      }, '',)

    },
    getCommunityVideoData () {
      Api.publicCourseHistory({is_expired: 1}, (res, coursePagination) => {
        this.videoData = res
        this.coursePagination = coursePagination
      })
    },
    changeView (type) {
      if (type !== 'communityVideo' && type !== 'communityLive') {
        this.isBtnBig = false
      }
      this.viewType = type
    },
    translateTag,
    formatMoment,
    changeWidth () {
      this.isBtnBig = !this.isBtnBig
    }
  },
  components: {
    NoContent
  }
}
