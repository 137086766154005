<template>
  <div class="course_expired">
    <div class="top">
      <div class="return">
        <router-link to="/user/course">
          <i class="iconfont icon-papa-rt">我的课程</i>
        </router-link>
      </div>
      <div class="title">过期课</div>
    </div>
    <div class="content">
      <div class="expired_tab">
        <div
          class="button button--small"
          :class="viewType === 'class' ? 'button--green' : ''"
          @click="changeView('class')"
        >
          班课
        </div>
        <div
          class="button button--small"
          :class="viewType === 'private' ? 'button--green' : ''"
          @click="changeView('private')"
        >
          1v1
        </div>
        <div
          class="button button__special"
          :class="`${
            viewType === 'communityLive' || viewType === 'communityVideo'
              ? 'button__special--green'
              : ''
          } ${isBtnBig ? 'button-big' : ''}`"
        >
          <span @click="changeWidth">公益课</span>
          <el-divider direction="vertical"></el-divider>
          <span
            :class="viewType === 'communityLive' ? 'span--green' : ''"
            @click="changeView('communityLive')"
            >直播课</span
          >
          <span
            :class="viewType === 'communityVideo' ? 'span--green' : ''"
            @click="changeView('communityVideo')"
            style="width: 6em"
            >往期视频</span
          >
        </div>
      </div>
      <div class="expired_list">
        <template v-if="viewType === 'class'">
          <div class="item" v-for="item in classData" :key="item.id">
            <template v-if="item.course.course_mode === 1">
              <div class="item_title">
                <div class="title_course">
                  <span
                    v-if="
                      translateTag(item.course.category_id).abbr != undefined
                    "
                    >{{ translateTag(item.course.category_id).abbr }}</span
                  >
                  <span class="course_type">直播</span>
                  {{ item.course.name }}
                </div>
              </div>
              <!--              <div class="item_range">直播安排{{formatMoment(item.whole.started_at,'YYYY/M/DD')}} - -->
              <!--                {{formatMoment(item.whole.ended_at,'YYYY/MM/DD')}} 共{{item.period.finished}}节-->
              <!--              </div>-->
              <!--              <div class="item_status">已完成课时/剩余课时 <span>{{item.period.finished}}</span>/{{item.period.total - -->
              <!--                item.period.finished}}-->
              <!--              </div>-->
              <div class="item_status">
                有效期至 {{ formatMoment(item.expired_at, "YYYY/MM/DD") }}
              </div>
            </template>
            <template v-else>
              <div class="item_title">
                <div class="title_course">
                  <span
                    v-if="
                      translateTag(item.course.category_id).abbr != undefined
                    "
                    >{{ translateTag(item.course.category_id).abbr }}</span
                  >
                  <span
                    class="course_type"
                    style="background: #e3f1fa; color: #225375"
                    >视频</span
                  >
                  {{ item.course.name }}
                </div>
              </div>
              <div class="item_range" style="color: #666">
                有效期至{{ formatMoment(item.expired_at, "YYYY/MM/DD") }}
              </div>
            </template>
          </div>
        </template>
        <no-content
          v-if="viewType === 'class' && classData.length === 0"
          :data="noContentData"
        ></no-content>
        <template v-if="viewType === 'private'">
          <div class="item" v-for="item in privateData" :key="item.id">
            <div class="item_title">
              <div class="title_course">
                <!--                <span-->
                <!--                    v-if="translateTag(item.category_id).abbr!=undefined">{{translateTag(item.category_id).abbr}}</span>-->
                {{ item.course_subject }}
              </div>
              <div class="title_teacher">{{ item.teacher }}老师</div>
            </div>
            <div class="item_range">
              总课时 {{ item.total_period }}
              <div>
                已完成课时/剩余课时
                <span style="color: #29d087">{{ item.finished_period }}</span
                >/{{ item.total_period - item.finished_period }}
              </div>
            </div>
            <div class="item_status">
              有效期至 {{ formatMoment(item.expired_at, "YYYY/MM/DD") }}
            </div>
          </div>
        </template>
        <no-content
          v-if="viewType === 'private' && privateData.length === 0"
          :data="noContentData"
        ></no-content>
        <template v-if="viewType === 'communityLive'">
          <div class="item" v-for="item in liveData" :key="item.id">
            <div class="item_title">
              <div class="title_course">
                <span
                  v-if="translateTag(item.course.category_id).abbr != undefined"
                  >{{ translateTag(item.course.category_id).abbr }}</span
                >
                <span
                  class="course_type"
                  style="background: #e3f1fa; color: #225375"
                  >视频</span
                >
                {{ item.course.name }}
              </div>
            </div>
            <div class="item_range" style="color: #666">
              有效期至{{ formatMoment(item.expired_at, "YYYY/MM/DD") }}
            </div>
          </div>
        </template>
        <no-content
          v-if="viewType === 'communityLive' && liveData.length === 0"
          :data="noContentData"
        ></no-content>
        <template v-if="viewType === 'communityVideo'">
          <div class="item" v-for="item in videoData" :key="item.id">
            <div class="item_title">
              <div class="title_course">
                <span
                  v-if="translateTag(item.course.category_id).abbr != undefined"
                  >{{ translateTag(item.course.category_id).abbr }}</span
                >
                <span
                  class="course_type"
                  style="background: #e3f1fa; color: #225375"
                  >视频</span
                >
                {{ item.course.name }}
              </div>
            </div>
            <div class="item_range" style="color: #666">
              有效期至{{ formatMoment(item.expired_at, "YYYY-MM-DD") }}
            </div>
          </div>
        </template>
        <no-content
          v-if="viewType === 'communityVideo' && videoData.length === 0"
          :data="noContentData"
        ></no-content>
      </div>
    </div>
  </div>
</template>

<script>
import ExpiredJs from "./Expired.js";

export default ExpiredJs;
</script>

<style lang="less" scoped>
.course_expired {
  width: 100%;
  box-sizing: border-box;
  padding: 0 18px;

  .top {
    width: 100%;
    box-sizing: border-box;
    padding: 18px;
    display: flex;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 20px;
    position: relative;

    .return {
      position: absolute;
      a {
        i {
          color: #999;
        }
      }
    }

    .title {
      width: 100%;
      text-align: center;
      font-size: 16px;
      color: #333333;
      font-weight: bold;
    }
  }

  .content {
    width: 100%;
    box-sizing: border-box;
    padding: 0 18px;

    .expired_tab {
      display: flex;

      .button {
        margin-right: 10px;
      }

      .button__special {
        .button(70px, 40px);
        overflow: hidden;

        span {
          width: 70px;
          display: inline-block;
          text-align: center;
        }

        .span--green {
          color: @papaGreen;
        }
      }

      .button__special--green {
        border: 1px solid @papaGreen;
      }

      .button-big {
        width: 255px;
      }
    }

    .expired_list {
      .item:nth-child(2n + 0) {
        margin-right: 0;
      }

      .item {
        width: 420px;
        height: 150px;
        padding: 20px;
        box-sizing: border-box;
        border: 1px solid #e6e6e6;
        border-radius: 5px;
        float: left;
        text-align: left;
        font-size: 14px;
        color: #333;
        margin: 30px 10px 0 0;
        opacity: 0.6;
        cursor: not-allowed;

        .item_pic {
          width: 176px;

          & > img {
            width: 100%;
            height: 100%;
          }

          float: left;
          margin-right: 25px;
        }

        .item_desc {
          height: 100%;
        }

        .item_title {
          font-size: 18px;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            background: #667482;
            color: #fff;
            padding: 1px 4px;
            margin-right: 8px;
            font-size: @fontSizeCon;
          }

          .course_type {
            background: @papaGreen;
          }
        }
      }
    }
  }
}
</style>
